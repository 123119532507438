<template>
    <div>
        <el-card class="management-report-card-box">
            <el-form>
                <div class="flex flex-row justify-between items-center mb-2">
                    <h2 class="text-base font-semibold mb-2">{{ title }}</h2>
                    <div class="flex items-center">
                        <InfoButton :content="infoContent" />
                        <el-switch v-model="localtoggleValue" active-color="#48c78e" inactive-color="#D1D5DB" @change="onToggleChange" />
                    </div>
                </div>
                <div v-if="toggleValue" v-loading="$waiting.is('loading-yearlyEventInfo')">
                    <el-input type="textarea" :disabled="inputDisabled" v-model="localInputText" rows="6" class="break-normal custom-textarea" />
                </div>
            </el-form>
            <CardEditButtons :toggle-value="toggleValue" :show-edit-button="showEditButton" :toggle-edit="toggleEdit" :close-modal="closeModal" :save-data="saveData" />
        </el-card>
    </div>
</template>
<script>
import { Switch } from "element-ui";
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        inputText: {
            type: String,
            default: "",
        },
        tooltipInfo: {
            type: String,
            default: "",
        },
        toggleValue: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            selectedYear: null,
            financialYearId: null,
            annualReportId: undefined,
            inputDisabled: true,
            showEditButton: true,
            localtoggleValue: this.toggleValue,
            infoContent: `Upplysning ska lämnas om sådana händelser av väsentlig betydelse för företaget som har inträffat under räkenskapsåret. Det kan avse a) beslutade viktiga förändringar i verksamheten, b) för företaget viktiga externa faktorer som påverkat dess ställning och resultat, och c) den omständigheten att företaget inte längre förutsätts fortsätta sin verksamhet. Även den omständigheten att bolaget har upprättat kontrollbalansräkning eller är skyldig att göra det ska framgå.<br /><br /> ÅRL 6 kap. 1 § andra stycket 2. K2 (BFNAR 2016:10) kapitel 4 punkt 9 och kapitel 5 punkt 4 och 7`,
            localInputText: this.inputText,
            initialInputText: "",
            isInitialInputTextSet: false,
        };
    },
    components: {
        "el-switch": Switch,
        CardEditButtons: () => import("./components/CardEditButtons.vue"),
        InfoButton: () => import("./components/InfoButton.vue"),
    },
    watch: {
        inputText(newVal) {
            this.localInputText = newVal;
            if (!this.isInitialInputTextSet) {
                this.initialInputText = newVal;
                this.isInitialInputTextSet = true;
            }
        },
        localInputText(newVal) {
            this.$emit("update:text", newVal);
        },
        toggleValue(newVal) {
            this.localtoggleValue = newVal;
        },
    },
    methods: {
        toggleEdit() {
            this.inputDisabled = !this.inputDisabled;
            this.showEditButton = !this.showEditButton;
        },
        onToggleChange(newVal) {
            const eventData = {
                source: 4,
                value: newVal,
            };
            this.$emit("toggle-change", eventData);
        },
        async saveData() {
            this.$emit("create");
            this.$notify.success({ title: 'Sparat "Väsentliga händelser under räkenskapsåret"' });
            this.toggleEdit();
        },
        closeModal() {
            const load = "loading-yearlyEventInfo";
            this.$emit("close", { load: load, inputTextNewValue: this.inputTextNewValue });
            this.toggleEdit();
        },
    },
    computed: {
        inputTextNewValue() {
            return this.initialInputText != this.localInputText;
        },
    },
};
</script>
